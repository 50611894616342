



















































































































import Vue from "vue";
import { messaging } from "@/code/api.messaging";
import * as models from "@/models";
import { G } from "@/globals";
import { accounts } from "@/code/api.accounts";
import { router } from "@/router";
import hiddenFileInput from "@/components/hidden-file-input.vue";
import videoPlayer from "@/components/videoPlayer.vue";

export default {
  name: "Message",
  components: { hiddenFileInput, videoPlayer },
  props: {
    passedThread: {
      default: null
    }
  },
  data: () => ({
    thread: <models.thread>{ pagedMessages: null },
    initializing: true,
    pageSize: 12,
    loadingMore: false,
    newTextMessage: null,
    autoRefresh: {
      lastMessageId: 0,
      blocked: false,
      timeout: 2000, //<--- 5 seconds
      handler: null
    },
    videoMessageData: {
      uploadingVideoMessage: false,
      videoUploadProgress: 0,
      videoWatching: false,
      watchTimeoutHandler: null
    }
  }),
  computed: {
    pagedMessages: function(): models.paged<models.message> {
      if (!this.thread.pagedMessages)
        return {
          items: [],
          hasPrevious: false,
          hasNext: false,
          skipped: 0,
          taken: 0
        };

      return this.thread.pagedMessages;
    },
    threadHasPendingVideos: function() {
      let msgs: models.message[] = this.thread.pagedMessages.items;
      if (msgs.length == 0) return false;

      return msgs.some(
        m => m.video && m.video.vidStatusId != models.videoStatus.Ready
      );
    }
  },
  watch: {
    initializing: function(newValue: boolean) {
      newValue ? G.showLoading() : G.hideLoading();
    },
    passedThread: async function(newValue) {
      await this.processPassedThread(newValue);
    }
  },
  created: function() {},
  mounted: async function() {
    // console.log(this.passedThread);
    // if (!this.passedThread) {
    //   // this.$router.go(-1);
    //   return;
    // }
    if (!this.passedThread && !this.$route.params.id) {
      this.$router.push({
        name: "Messages"
      });

      return;
    }
    this.processPassedThread(
      this.passedThread || { id: this.$route.params.id, subject: "Loading..." }
    );
    // this.processPassedThread(this.passedThread);
    // this.thread = { ...this.thread, ...this.passedThread };
    // G.data.customTitle = this.thread.subject;
    // G.showLoading();
    // //====
    // await this.loadThread(0, this.pageSize);
    // this.initializing = false;
    // this.scrollToBottom();

    // this.autoRefresh.handler = setTimeout(
    //   this.checkForNewMessages,
    //   this.autoRefresh.timeout
    // );
  },
  methods: {
    processPassedThread: async function(thread: models.thread) {
      if (!thread) {
        this.$router.push({
          name: "Messages"
        });

        return;
      }
      if (this.autoRefresh.handler) clearInterval(this.autoRefresh.handler);
      this.pageSize = 12;
      this.initializing = true;
      this.loadingMore = false;
      this.newTextMessage = null;

      this.thread = { ...this.thread, ...thread };
      G.data.customTitle = `Inbox : ${this.thread.subject}`;
      G.showLoading();

      await this.loadThread(0, this.pageSize);

      this.autoRefresh.handler = setTimeout(
        this.checkForNewMessages,
        this.autoRefresh.timeout
      );
    },

    checkForNewMessages: function() {
      let noLastMessageId =
        !this.autoRefresh.lastMessageId || this.autoRefresh.lastMessageId == 0;

      if (this.autoRefresh.blocked || noLastMessageId) {
        this.setNextInterval();
        return;
      }

      this.autoRefresh.blocked = true;
      console.log("checkForNewMessages");

      //Scaling Timeout
      if (this.autoRefresh.timeout != 30000) {
        //Slow Down
        this.autoRefresh.timeout = this.autoRefresh.timeout * 1.75;
        //Cap
        if (this.autoRefresh.timeout > 30000) this.autoRefresh.timeout = 30000;
        //Change
        this.setNextInterval();
      }

      messaging
        .getUnviewedMessagesCount(
          this.thread.id,
          this.autoRefresh.lastMessageId
        )
        .then(async newMessagesCount => {
          console.log(newMessagesCount);

          if (newMessagesCount > 0) {
            return await this.loadThread(0, newMessagesCount + 1);
          }
        })
        .finally(() => {
          this.autoRefresh.blocked = false;
        });
    },
    setNextInterval: function() {
      // clearInterval(this.autoRefresh.handler);
      console.log("setting to " + this.autoRefresh.timeout);

      clearInterval(this.autoRefresh.handler);
      this.autoRefresh.handler = setTimeout(
        this.checkForNewMessages,
        this.autoRefresh.timeout
      );
    },
    loadThread: async function(
      skip: number,
      take: number,
      isLoadingOlderMessages?: boolean
    ) {
      if (!this.thread) return;
      return messaging
        .getThread(
          this.thread.id,
          skip,
          take,
          G.data.isSeekerMode ? null : accounts.currentOrgId
        )
        .then(thrd => {
          // console.log(thrd);
          let newItems = thrd.pagedMessages.items;
          let sortFunc = (a, b) => (a.id > b.id ? 1 : -1);
          // newItems = newItems.reverse();

          if (newItems.length > 0) {
            console.log("new: " + newItems.length.toString());
            this.autoRefresh.timeout = 2000;
            this.setNextInterval();
          }

          if (!this.thread.pagedMessages) {
            //First Load
            thrd.pagedMessages.items = thrd.pagedMessages.items.reverse();
            this.thread = thrd;
          } else {
            //Append
            let combinedItems: models.message[] = [
              ...newItems.filter(
                m => !this.thread.pagedMessages.items.some(xm => xm.id == m.id)
              ),
              ...this.thread.pagedMessages.items
            ].sort(sortFunc);
            this.thread = thrd;
            this.thread.pagedMessages.items = combinedItems;
          }
          if (this.threadHasPendingVideos) this.watchProcessingVideos(3000);
          this.markAsRead(thrd, true);
        })
        .catch(err => {
          G.log.error("Messaging.Message.loadThread", err);
          this.$router.push({
            name: "Messages"
          });
        })
        .finally(() => {
          this.loadingMore = false;
          isLoadingOlderMessages ? this.scrollToTop() : this.scrollToBottom();
          G.hideLoading();
          {
            // Last Message Id
            this.autoRefresh.lastMessageId = Math.max.apply(
              null,
              this.thread.pagedMessages.items.map(m => m.id)
            );
            console.log(this.autoRefresh.lastMessageId);
          }
        });
    },
    loadMore: function() {
      // if (!this.jobPostsExtended.hasNext) return;
      this.loadingMore = true;
      this.loadThread(this.pagedMessages.items.length, this.pageSize, true);
    },
    sendMessage: function() {
      if (!this.newTextMessage || !this.newTextMessage.trim()) return;
      let msg: string = this.newTextMessage.trim();
      messaging
        .sendMessage(
          this.thread.id,
          this.newTextMessage,
          G.data.isSeekerMode ? null : accounts.currentOrgId
        )
        .then(msg => {
          // this.autoRefresh.lastMessageId = msg.id;
          this.thread.pagedMessages.items.push(msg);
          // this.$vuetify.goTo(`[data-msg-id="${(msg.id - 1).toString()}"]`); //, options
          this.autoRefresh.timeout = 2000;
          this.setNextInterval();
        })
        .catch(err => {
          G.log.error("Messaging.Thread.sendMessage", {
            error: err,
            message: msg,
            isSeeker: G.data.isSeekerMode
          });
          this.newTextMessage = msg;
        })
        .finally(() => {
          this.scrollToBottom();
        });
      this.newTextMessage = null;
    },
    handleUploadProgress(percentage: number) {
      this.videoMessageData.videoUploadProgress = percentage;
      this.scrollToBottom();
    },
    sendVideoMessage: function(file: File) {
      this.videoMessageData.uploadingVideoMessage = true;
      this.scrollToBottom();

      messaging
        .sendVideoMessage(
          this.thread.id,
          file,
          this.handleUploadProgress,
          G.data.isSeekerMode ? null : accounts.currentOrgId
        )
        .then(msg => {
          this.autoRefresh.lastMessageId = msg.id;
          this.thread.pagedMessages.items.push(msg);
          // this.$vuetify.goTo(`[data-msg-id="${(msg.id - 1).toString()}"]`); //, options
          this.autoRefresh.timeout = 2000;
          this.setNextInterval();
        })
        .catch(err => {
          G.log.error("Messaging.Thread.sendVideoMessage", {
            error: err,
            isSeeker: G.data.isSeekerMode
          });
        })
        .finally(() => {
          this.scrollToBottom();
          this.videoMessageData.uploadingVideoMessage = false;
          this.videoMessageData.videoUploadProgress = 0;
          this.watchProcessingVideos(3000);
        });
    },

    getMessageHeader: function(msg: models.message) {
      let t: models.thread = this.thread;
      if (msg.isMine) {
        return G.data.isSeekerMode ? "Me" : accounts.currentOrg.name;
      } else {
        return t.fromHeader;
      }
    },
    scrollToBottom: function() {
      var objDiv = <HTMLDivElement>(
        document.getElementsByClassName("t-msg-list")[0]
      );
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    scrollToTop: function() {
      var objDiv = <HTMLDivElement>(
        document.getElementsByClassName("t-msg-list")[0]
      );
      objDiv.scrollTop = 0;
    },
    markAsRead: async function(thrd: models.thread, delay?: boolean) {
      setTimeout(
        () => {
          messaging
            .markAsRead(
              thrd.id,
              G.data.isSeekerMode ? null : accounts.currentOrgId
            )
            .then(() => {
              //thrd.newMessagesCount = 0;
              G.newMessagesWatch.getNewMessagesCountFromServer();
            })
            .catch(err => {
              G.log.error("Messaging.Thread.markAsRead", {
                error: err,
                threadId: this.thread.id,
                isSeeker: G.data.isSeekerMode
              });
            });
        },
        delay ? 3000 : 5
      );
    },
    videoSelectCancelled: function() {},
    watchProcessingVideos: function(interval: number) {
      if (this.videoMessageData.videoWatching) return;
      this.videoMessageData.videoWatching = true;

      let RefreshFunction = (interval: number) => {
        this.videoMessageData.watchTimeoutHandler = setTimeout(() => {
          console.log("checking processing videos");
          messaging
            .getThreadVideoMessages(this.thread.id)
            .then(serverVideoMessages => {
              let existingNotReadyVideoMessages: models.message[];
              existingNotReadyVideoMessages = this.thread.pagedMessages.items.filter(
                m => m.video && m.video.vidStatusId != models.videoStatus.Ready
              );

              existingNotReadyVideoMessages.forEach(em => {
                em.video = serverVideoMessages.find(sm => sm.id == em.id).video;
              });

              // Still has processing videos?
              if (
                serverVideoMessages.some(
                  m => m.video.vidStatusId != models.videoStatus.Ready
                )
              ) {
                RefreshFunction(3000);
                // this.scrollToBottom();
              } else {
                this.videoMessageData.videoWatching = false;
                this.scrollToBottom();
              }
            });
        }, interval);
      };

      RefreshFunction(interval);
    }
  },
  beforeDestroy() {
    if (this.autoRefresh.handler) clearInterval(this.autoRefresh.handler);
    if (this.videoMessageData.watchTimeoutHandler)
      clearInterval(this.videoMessageData.watchTimeoutHandler);
  }
};
