









































































































import Vue from "vue";
import { messaging } from "@/code/api.messaging";
import * as models from "@/models";
import { accounts } from "@/code/api.accounts";
import { G } from "@/globals";

export default {
  name: "Messages",

  data: () => ({
    threads: { items: [] } as models.paged<models.thread>,
    initializing: true,
    pageSize: 5,
    loadingMore: false,
    currentThread: null as models.thread
  }),

  watch: {
    initializing: function(newValue: boolean) {
      // newValue ? this.G.showLoading() : this.G.hideLoading();
    }
    // currentThread: function(newValue: models.thread) {
    //   if (newValue) {
    //     G.topLevelVue.currentViewSettings = {
    //       toolbar: true,
    //       bottomNav: this.$vuetify.breakpoint.xs ? false : true,
    //       feedbackFabVisible: false,
    //       toolbarSideButton: this.$vuetify.breakpoint.xs
    //         ? { style: "back" }
    //         : null,
    //       backButtonCallback: () => {
    //         this.currentThread = null;
    //       }
    //     };
    //   } else {
    //     G.topLevelVue.currentViewSettings = {
    //       toolbar: true,
    //       bottomNav: true,
    //       feedbackFabVisible: false
    //     };
    //   }
    // }
  },
  created: function() {
    // this.G.showLoading();
  },

  activated: function() {
    this.G.customTitle = "Inbox";
    //  toolbar Items = [{ icon: "refresh", onClick: this.refreshThreads }];
  },
  mounted: async function() {
    await this.loadThreads(0, this.pageSize);
    this.initializing = false;
  },
  methods: {
    refreshThreads: function() {
      this.initializing = true;

      this.loadThreads(0, this.pageSize, true);
    },
    loadThreads: function(
      skip: number,
      take: number,
      overwriteExisting?: boolean
    ) {
      return messaging
        .getActiveThreads(
          skip,
          take,
          this.G.isSeekerMode ? null : accounts.currentOrgId
        )
        .then(thrds => {
          if (overwriteExisting) {
            this.threads = thrds;
          } else {
            let combinedItems = [...this.threads.items, ...thrds.items];
            this.threads = thrds;
            this.threads.items = combinedItems;
          }
        })
        .catch(err => {
          this.G.log.error("Messaging.Inbox.loadThreads", err);
        })
        .finally(() => {
          this.loadingMore = false;
          this.initializing = false;
        });
    },
    loadMore: function() {
      // if (!this.jobPostsExtended.hasNext) return;
      this.loadingMore = true;
      this.loadThreads(this.threads.items.length, this.pageSize);
    },
    viewMessage: function(thrd: models.thread) {
      // console.log({ passedThread: thrd });
      this.markAsRead(thrd, true);

      this.currentThread = thrd;
      this.$router.push({
        name: "Messages__Message",
        // path: "/messaging/thread",
        params: { passedThread: thrd, id: thrd.id }
      });
    },
    markAsRead: async function(thrd: models.thread, delay?: boolean) {
      setTimeout(
        () => {
          messaging
            .markAsRead(
              thrd.id,
              this.G.isSeekerMode ? null : accounts.currentOrgId
            )
            .then(() => {
              thrd.newMessagesCount = 0;
              G.newMessagesWatch.getNewMessagesCountFromServer();
            })
            .catch(err => {
              this.G.log.error("Messaging.Inbox.markAsRead", {
                error: err,
                threadId: thrd.id,
                isSeeker: this.G.isSeekerMode
              });
            });
        },
        delay ? 3000 : 5
      );
    }
  },
  computed: {
    isSeekerMode: function(): boolean {
      return this.G.isSeekerMode;
    },
    currentThreadId: function() {
      if (this.currentThread) {
        return this.currentThread.id;
      } else {
        return null;
      }
    }
  }
};
