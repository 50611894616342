var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    {
      attrs: {
        "is-parent-child": "",
        "parent-class": _vm.dynamicGradiantBackground
      }
    },
    [
      _c(
        "m-toolbar",
        {
          staticClass: "elevation-0 transparent",
          attrs: {
            slot: "toolbar",
            dark: "",
            "text-color": "white--text",
            xclass: "[G.isSeekerMode?'bg-sk':'bg-rc']",
            title: "Messages"
          },
          slot: "toolbar"
        },
        [
          _c(
            "template",
            { slot: "toolbarButtons" },
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "" },
                  on: { click: _vm.refreshThreads }
                },
                [_c("v-icon", [_vm._v("refresh")])],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.initializing
        ? [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" }
            })
          ]
        : !_vm.initializing && _vm.threads.items.length == 0
        ? [
            _c(
              "v-flex",
              { attrs: { xs10: "", "offset-xs1": "" } },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-3 primary--text", attrs: { light: "" } },
                  [
                    _c("v-card-text", { attrs: { "primary-title": "" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "headline primary--text text-xs-center"
                        },
                        [_vm._v("YOUR INBOX IS EMPTY!")]
                      )
                    ]),
                    _vm.isSeekerMode
                      ? _c("v-card-text", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "\n          The more you apply for relevant jobs, the better your\n          chances are to get contacted.\n        "
                          )
                        ])
                      : [
                          _c("v-card-text", { staticClass: "text-xs-center" }, [
                            _vm._v(
                              "\n            Why don't you go\n            discover some talent, and get in touch with them?\n          "
                            )
                          ]),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { attrs: { "primary-title": "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "headline primary--text text-xs-center"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        round: "",
                                        color: "primary",
                                        dark: "",
                                        to: { name: "Orgs_Discover" }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("explore")
                                      ]),
                                      _vm._v("discover\n              ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                  ],
                  2
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-list",
              {
                staticClass: "messages-list",
                attrs: { "two-line": "", dark: "" }
              },
              [
                _vm._l(_vm.threads.items, function(thrd, index) {
                  return [
                    _c(
                      "v-list-tile",
                      {
                        key: index,
                        class: _vm.currentThreadId == thrd.id ? "active" : "",
                        attrs: {
                          ripple: "",
                          color: "accent darken-4",
                          "active-class": "accent-gradiantx",
                          light: _vm.currentThreadId == thrd.id
                        },
                        on: {
                          click: function($event) {
                            return _vm.viewMessage(thrd)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-tile-content",
                          [
                            _c("v-list-tile-title", {
                              class: {
                                "font-weight-bold": thrd.newMessagesCount > 0
                              },
                              domProps: { innerHTML: _vm._s(thrd.subject) }
                            }),
                            _c(
                              "v-list-tile-sub-title",
                              {
                                class: {
                                  "font-weight-bold": thrd.newMessagesCount > 0
                                }
                              },
                              [
                                _c("span", { staticClass: "xtext--primary" }, [
                                  _vm._v(_vm._s(thrd.fromHeader))
                                ]),
                                _vm._v(
                                  "\n              —\n              " +
                                    _vm._s(thrd.fromSubHeader) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-action",
                          { staticClass: "message-actions" },
                          [
                            _c(
                              "v-list-tile-action-text",
                              {
                                staticClass: "message-date",
                                class: {
                                  "font-weight-bold": thrd.newMessagesCount > 0
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(
                                      thrd.lastMessageDate,
                                      "calendar"
                                    )
                                  )
                                )
                              ]
                            ),
                            thrd.newMessagesCount == 0
                              ? _c(
                                  "v-icon",
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("drafts")]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { color: "yellow darken-2" } },
                                  [_vm._v("mail")]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }),
                _vm.threads.hasNext
                  ? _c(
                      "v-list-tile",
                      {
                        attrs: { ripple: "", color: "accent darken-4" },
                        on: { click: _vm.loadMore }
                      },
                      [
                        _c(
                          "v-list-tile-content",
                          [
                            _c(
                              "v-list-tile-title",
                              { staticClass: "font-weight-boldx" },
                              [_vm._v("Load more...")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-action",
                          { staticClass: "message-actions" },
                          [
                            _vm.loadingMore
                              ? _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "white" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            ),
            !_vm.threads.hasNext
              ? _c("dot-steps", { attrs: { "current-step": 0 } })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }